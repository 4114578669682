export const summaryPageTopMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const AddBotMenu = {
	head: {
		id: 'head',
		text: 'Bot',
		icon: 'Extension',
	},
	addBot: {
		id: 'newBot',
		text: 'Add Bot',
		path: 'addbot',
		icon: 'AddBox',
	},
};

export const TwitchInactiveMenu = {
	head: {
		id: 'head',
		text: 'Twitch',
		icon: 'Extension',
	},
	addTwitch: {
		id: 'newTwitch',
		text: 'Add Twitch',
		path: 'twitch/add',
		icon: 'AddBox',
	},
}

export const TwitchMenu = {
	head: {
		id: 'head',
		text: 'Twitch',
		icon: 'Extension',
	},
	twdashboard: {
		id: 'twdashboard',
		text: 'Dashboard',
		path: 'twitch/dashboard',
		icon: 'Dashboard',
	},

	twcommands: {
		id: 'twitchcommands',
		text: 'Commands',
		icon: 'Api',
		path: "page-layout",
		subMenu: {
			twcommandoverview: {
				id: 'twcommandoverview',
				text: 'Overview',
				path: 'twitch/commands/overview',
				icon: 'TableRows',
			},
			twcommandadd: {
				id: 'twcommandadd',
				text: 'Add Command',
				path: 'twitch/commands/add',
				icon: 'AddBox',
			},
			twcommandalias: {
				id: 'twcommandalias',
				text: 'Command-Alias',
				path: 'twitch/commands/alias',
				icon: 'AddBox',
			},
		},
	},

	twreactions: {
		id: 'twreactions',
		text: 'Reactions',
		icon: 'ChatBubble',
		path: "page-layout",
		subMenu: {
			twreactionoverview: {
				id: 'twreactionoverview',
				text: 'Overview',
				path: 'twitch/reaction/overview',
				icon: 'TableRows',
			},
			twreactionadd: {
				id: 'twreactionadd',
				text: 'Add Reaction',
				path: 'twitch/reaction/add',
				icon: 'AddBox',
			},
		},
	},

	twautoaction: {
		id: 'twautoaction',
		text: 'AutoActions',
		icon: 'AccessTime',
		path: "page-layout",
		subMenu: {
			twautoactionoverview: {
				id: 'twautoactionoverview',
				text: 'Overview',
				path: 'twitch/autoaction/overview',
				icon: 'TableRows',
			},
			twautoactionnadd: {
				id: 'twautoactionadd',
				text: 'Add AutoAction',
				path: 'twitch/autoaction/add',
				icon: 'AddBox',
			},
		},
	},

	tweventactions: {
		id: 'tweventactions',
		text: 'EventActions',
		icon: 'Event',
		path: "page-layout",
		subMenu: {
			tweventactionsoverview: {
				id: 'tweventactionsoverview',
				text: 'Overview',
				path: 'twitch/eventactions/overview',
				icon: 'TableRows',
			},
			tweventactionsadd: {
				id: 'tweventactionsadd',
				text: 'Add EventAction',
				path: 'twitch/eventactions/add',
				icon: 'AddBox',
			},
		},
	},
	twSecurity: {
		id: 'twsecurity',
		text: 'Security',
		icon: 'Lock',
		path: "page-layout",
		subMenu: {
			twautoban: {
				id: 'twautoban',
				text: 'Autobann',
				icon: 'RemoveCircleOutline',
				path: "twitch/autoban",
			},
			twlinkfilter: {
				id: 'twurlfilter',
				text: 'URL-Filter',
				icon: 'FilterAlt',
				path: "twitch/urlfilter",
			},
			/*followbots: {
				id: 'twfollowbots',
				text: 'FollowBots',
				icon: 'FilterAlt',
				path: "twitch/followbots",
			},*/
		}
	},
};

export const DiscordInactiveMenu = {
	head: {
		id: 'head',
		text: 'Discord',
		icon: 'Extension',
	},
	addDiscord: {
		id: 'newDiscord',
		text: 'Add Discord',
		path: 'discord/add',
		icon: 'AddBox',
	},
}

export const DiscordMenu = {
	head: {
		id: 'head',
		text: 'Discord',
		icon: 'Extension',
	},
	misc: {
		id: 'discordmisc',
		text: 'Misc',
		icon: 'Settings',
		path: 'page-layout',
		subMenu: {
			sendMessage: {
				id: 'sendmessage',
				text: 'Send Message',
				path: 'discord/misc/sendmessage',
				icon: 'Send',
			},
		},
	},
};

export const BotMenu = {
	head: {
		id: 'head',
		text: 'Bot',
		icon: 'Extension',
	},
	aioslink: {
		id: 'aioslink',
		text: 'aioslink',
		path: '/aioslink',
		icon: 'Link',
	},
	/*subscription: {
		id: 'subscription',
		text: 'Subscription',
		path: 'subscription',
		icon: 'Payments',
	},*/
	cv: {
		id: 'cv',
		text: 'Counters & Vars',
		path: 'bot/countvars',
		icon: 'DataUsage',
	},
	giveaway: {
		id: 'giveaway',
		text: 'Giveaway',
		path: 'bot/giveaway',
		icon: 'CardGiftcard',
	},
	settings: {
		id: 'botsettings',
		text: 'Settings',
		path: 'bot/settings',
		icon: 'settings',
	},
};

export const AiostoolMenu = {
	head: {
		id: 'head',
		text: 'aiostool',
		icon: 'Extension',
	},
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: 'aiostool/dashboard',
		icon: 'Dashboard',
	},
	workshop: {
		id: 'workshop',
		text: 'Workshop',
		path: 'aiostool/workshop',
		icon: 'Work',
	}
}

export const HelpCenter = {
	head: {
		id: 'head',
		text: 'Misc',
		icon: 'Extension',
	},
	faq: {
		id: 'faq',
		text: 'Docs',
		path: 'https://wiki.aiosbot.com',
		icon: 'help',
		isDisable: false,
		isExternal: true,
	},
	donate: {
		id: 'donate',
		text: 'Donate',
		path: 'https://ko-fi.com/aiosbot',
		icon: 'Favorite',
		isDisable: false,
		isExternal: true,
	},
};

export const authPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'sign-up',
		icon: 'PersonAdd',
	},
	activate: {
		id: 'activate',
		text: 'Login',
		path: 'Login',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '404',
		icon: 'ReportGmailerrorred',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const productsExampleMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
