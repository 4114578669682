// @ts-nocheck
import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [notifications, setNotifications] = useState([]);
	const [notificationCount, setNotificationCount] = useState(0);
	const { userData, setUser } = useContext(AuthContext);
	const [bots, setBots] = useState([]);
	const [selectedBot, setSelectedBot] = useState('Select Bot');
	const navigate = useNavigate();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { setIsOpen } = useTour();
	useEffect(() => {
		// Initialize selected bot name from localStorage if available
		//const savedBotName = localStorage.getItem('selectedBotName');

		if (userData.token) {
			if (userData.bot?.name) {	
				setSelectedBot(userData.bot.name);
			}
			fetch(`https://userserver.aiosbot.com/notifications`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: userData.token
				}
			})
				.then((res) => res.json())
				.then((data) => {
					var current = new Date();
					let res = data.result;
					for (let i = 0; i < res.length; i++) {
						var minutes = Math.floor((current.getTime() - new Date(res[i].date).getTime()) / 1000 / 60);
						let ago = "now";
						if (minutes >= 5 && minutes < 60)
							ago = minutes + " min";
						if (minutes >= 60)
							ago = Math.floor((minutes / 60)) + " hours";
						if (minutes >= 60 * 24)
							ago = Math.floor(((minutes / 60) / 24)) + " days";
						res[i].ago = ago;
					}
					setNotifications(data.result);
					let count = 0;
					data.result.forEach(item => {
						if (item.read == 0)
							count++;
					})
					setNotificationCount(count);
				})
				.catch((err) => {
				});

			fetch(`https://botapi.aiosbot.com/getAllBots`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: userData.token,
				}
			})
				.then((res) => res.json())
				.then((data) => {
					setBots(data);
					// Initialize selected bot name if there's a botId in the token
					const currentBotId = userData.botId;
					if (currentBotId) {
						const currentBot = data.find(b => b.id === currentBotId);
						if (currentBot) {
							setSelectedBot(currentBot.name);
						}
					}
				})
				.catch((err) => {
				});
		}
	}, [userData]);

	const selectBot = (id) => {
		fetch(`https://userserver.aiosbot.com/newBotIDToken`, {
			headers: {
				'Content-Type': 'application/json',
				authorization: userData.token,
				botid: id
			}
		})
			.then((res) => res.json())
			.then((data) => {
				sessionStorage.setItem('token', data.authtoken);
				// Update selected bot name before reload
				const bot = bots.find(b => b.id === id);
				setSelectedBot(id === -1 ? 'New Bot...' : bot?.name || 'Select Bot');
				// Store the selected bot name in localStorage to persist across page reload
				//localStorage.setItem('selectedBotName', id === -1 ? 'New Bot...' : bot?.name || 'Select Bot');
				window.location.href = '/';
			})
			.catch((err) => {
			});
	}

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'
							aria-label='Toggle dark mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle fullscreen'
						/>
					</Popovers>
				</div>

				{/*	Notifications */}
				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div>

				{/* Settings Dropdown */}
				<div className='col-auto'>
					<Dropdown align="end">
						<Dropdown.Toggle
							as={Button}
							{...styledBtn}
							className='btn-only-icon no-dropdown-arrow'
						>
							<Icon icon='Settings' />
						</Dropdown.Toggle>

						<style>
							{`
			.no-dropdown-arrow::after {
				display: none !important;
			}
			`}
						</style>

						<Dropdown.Menu
							className={classNames({
								'dropdown-menu-dark': darkModeStatus
							})}
						>
							<Dropdown.Item
								onClick={() => navigate('/profile-settings')}
								className='px-3 py-2 d-flex align-items-center gap-2'
							>
								<Icon icon='Lock' />
								<span>Change Password</span>
							</Dropdown.Item>
							{userData?.bot?.premium >= 1 && (<>{
								/*<Dropdown.Item
									onClick={() => navigate('/subscription/manage')}
									className='px-3 py-2 d-flex align-items-center gap-2'
								>
									<Icon icon='Settings' />
									<span>Manage Subscription</span>
								</Dropdown.Item>*/}
								</>)}
						</Dropdown.Menu>
					</Dropdown>
				</div>

				{/* Quick Panel */}
				<div className='col-auto' data-tour="BotSelectionButton">
					<Dropdown align="end">
						<Dropdown.Toggle
							as={Button}
							{...styledBtn}
							className='d-flex align-items-center gap-2 px-3'
							style={{ minWidth: '160px', justifyContent: 'flex-start' }}
						>
							<Icon icon='Robot' />
							<span className='text-truncate'>{selectedBot}</span>
							{userData?.bot?.premium >= 1 && (
								<span className={`small ms-2`} style={{ color: userData.bot.premium === 1 ? '#C0C0C0' : '#FFD700' }}>
									{userData.bot.premium === 1 ? 'Silver' : 'Gold'}
								</span>
							)}
							<Icon icon='ChevronDown' className='ms-auto' />
						</Dropdown.Toggle>

						<Dropdown.Menu
							style={{ minWidth: '200px' }}
							className={classNames({
								'dropdown-menu-dark': darkModeStatus
							})}
						>
							<Dropdown.Item
								onClick={() => selectBot(-1)}
								className={classNames(
									'px-3 py-2 d-flex align-items-center gap-2 fw-bold',
									{
										'bg-l25-info': !darkModeStatus,
										'bg-lo25-info': darkModeStatus,
									}
								)}
							>
								<Icon icon='Add' />
								<span>New Bot...</span>
							</Dropdown.Item>

							{bots.length > 0 && <Dropdown.Divider />}

							{bots.map((value, key) => (
								<Dropdown.Item
									key={`bot${key}`}
									onClick={() => selectBot(value.id)}
									className={classNames(
										'px-3 py-2 d-flex align-items-center gap-2',
										{
											'active': value.name === selectedBot || value.id === userData.botId
										}
									)}
								>
									<Icon icon='Robot' />
									<span className='text-truncate'>{value.name}</span>
									<div className='ms-auto d-flex align-items-center gap-2'>
										{value.premium >= 1 && (
											<span className={`small`} style={{ color: value.premium === 1 ? '#C0C0C0' : '#FFD700' }}>
												{value.premium === 1 ? 'Silver' : 'Gold'}
											</span>
										)}
										{value.id === userData.botId && (
											<Icon icon='Check' className='text-success' />
										)}
									</div>
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</div>

				{/* Logout */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Logout'>
						<Button
							{...styledBtn}
							icon='Logout'
							onClick={() => {
								sessionStorage.clear();
								localStorage.clear();
								setUser('');
								window.location.href = '/';
							}}
							aria-label='Logout'
						/>
					</Popovers>
				</div>
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					{
						notifications.map((value, key) => (
							<Alert key={`notification${key}`} icon='ViewInAr' isOutline color='warning' isDismissible className='flex-nowrap'>
								<AlertHeading tag={"h5"}>{value.title}</AlertHeading>
								{value.text}
							</Alert>
						))
					}
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
