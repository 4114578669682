// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
import Input from '../../../components/bootstrap/forms/Input';
import Popovers from '../../../components/bootstrap/Popovers';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import showNotification from '../../../components/extras/showNotification';
import Textarea from '../../../components/bootstrap/forms/Textarea';


const AiosLinkPage = () => {
    const { userData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: '',
        background: '',
        profilepicture: '',
        color: '',
        sitename: '',
        textcolor: '',
        description: ''
    });
    const [tmpdata, settmpData] = useState({
        name: '',
        background: '',
        profilepicture: '',
        color: '',
        sitename: '',
        textcolor: '',
        description: ''
    });
    const [loading, setLoading] = useState(true);
    const [links, setLinks] = useState([]);
    const [showModal, setShowModal] = useState(false);

    var botPremiumStatus = userData.bot ? userData.bot.premium : 0; // Extract premium status

    useEffect(() => {
        if (userData.token) {
            fetchAiosLinkData();
            botPremiumStatus = userData.bot ? userData.bot.premium : 0;
        }
    }, [userData]);

    const fetchAiosLinkData = () => {
        fetch('https://botapi.aiosbot.com/aioslink', {
            headers: {
                authorization: userData.token
            }
        })
            .then((res) => res.json())
            .then((result) => {
                if (result) {
                    setData(result.data);
                    settmpData(result.data);
                    fetch('https://botapi.aiosbot.com/aioslink_link', {
                        headers: {
                            authorization: userData.token
                        }
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            if (data) {
                                setLinks(data.data);
                            }
                        })
                        .catch((error) => {
                            console.log('Error fetching data:', error);
                        });
                }
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        if (data.name) {
            setLoading(false);
        }
    }, [data]);

    function handleChangeSettings(e){
        let newdata = JSON.parse(JSON.stringify(data));
        let dataname = e.target.name;
        newdata[dataname] = e.target.value;
        settmpData(newdata);
    }

    function changeSettings() {
        setLoading(true);
        if(tmpdata.name.length < 3) {
            showNotification("Failed", "The path of the site must be at least 3 characters long", "danger");
            setLoading(false);
            return;
        }
        // test if path only contains letters, numbers - and _
        if(!tmpdata.name.match(/^[a-zA-Z0-9_-]*$/)) {
            showNotification("Failed", "The path of the site may only contain letters, numbers, - and _", "danger");
            setLoading(false);
            return;
        }
        fetch(`https://botapi.aiosbot.com/aioslink`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                authorization: userData.token
            },
            body: JSON.stringify({
                data: tmpdata
            })
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.status === 200) {
                    setData(data.data);
                    settmpData(data.data);
                    setLoading(false);
                } else{
                    setLoading(false);
                    showNotification("Failed", data.message, "danger");
                }
            })
            .catch((err) => {
                console.log('Error fetching data:', err);
            });
    }

    function deleteURL(id) {
        setLoading(true);
        fetch(`https://botapi.aiosbot.com/aioslink_link`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                authorization: userData.token
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setLinks(data.data);
                    setLoading(false);
                    showNotification("Deleted", `URL was deleted`, "success")
                }
            })
            .catch((err) => {
                console.log('Error fetching data:', err);
            });
    }

    function openModal() {
        setnewURLData({
            url: '',
            urlname: '',
            icon: '',
            color: '#000000',
            rank: links.length + 1
        });
        setShowModal(true);
    }

    const [newURLData, setnewURLData] = useState({
        url: '',
        urlname: '',
        icon: '',
        color: '#000000',
        rank: 0
    });

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setnewURLData((prevState) => ({
            ...prevState,
            [name]: type === "number" ? Number(value) : value, // Nummernwerte konvertieren
        }));
    };

    function addURL(e) {
        e.preventDefault();
        setLoading(true);
        fetch(`https://botapi.aiosbot.com/aioslink_link`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                authorization: userData.token
            },
            body: JSON.stringify(newURLData)
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    setLinks(data.data);
                    setLoading(false);
                    setShowModal(false);
                    showNotification("Saved", `URL was saved`, "success")
                }
            })
            .catch((err) => {
                console.log('Error fetching data:', err);
            });
    }

    return (
        <PageWrapper title='aioslink'>
            <Modal size="lg" setIsOpen={setShowModal} isOpen={showModal}>
                <ModalHeader>
                    <ModalTitle id="AddURLModal">Add URL</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={addURL}>
                        <div>
                            <Label>URL Name</Label>
                            <Input type="text" name="urlname" maxlength="50" value={newURLData.urlname} onChange={handleChange} />
                        </div><br />
                        <div>
                            <Label>URL</Label>
                            <Input type="url" name="url" maxlength="1000" value={newURLData.url} onChange={handleChange} />
                        </div><br />
                        <div>
                            <Label>Icon URL</Label>
                            <Input type="text" name="icon" maxlength="255" value={newURLData.icon} onChange={handleChange} />
                        </div><br />
                        <div>
                            <Label>Color</Label>
                            <Input type="color" name="color" value={newURLData.color} onChange={handleChange} />
                        </div><br />
                        <div>
                            <Label>Order</Label>
                            <Input type="number" name="rank" value={newURLData.rank} onChange={handleChange} />
                        </div><br />
                        <div>
                            <Button type="submit" color="success">Add</Button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            <Page>
                <div className="row mb-3">
                    <Card>
                        <CardBody>
                            <div>
                                <Label>Your URL</Label>
                                <Input type="text" readOnly value={`https://aioslink.me/${data.name}`} />
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <Card>
                            <CardHeader>
                                <CardTitle>Site Configuration</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <form>
                                    <div>
                                        <Label>Site Path</Label>
                                        <Popovers placement='auto' trigger='hover' desc="This is the path where your site is reachable. You can only change it, if your bot has gold status." title="Site Path">
                                            <Input type="text" name="name" maxlength="50" onChange={handleChangeSettings} value={tmpdata.name} placeholder='Your site path' readOnly={botPremiumStatus != 2} />
                                        </Popovers>
                                    </div>
                                    <div>
                                        <Label>Site Name</Label>
                                        <Input type="text" name="sitename" maxlength="50" onChange={handleChangeSettings} value={tmpdata.sitename} placeholder='Your site name' />
                                    </div>
                                    <div>
                                        <Label>Profile Color</Label>
                                        <Input type="color" name="color" onChange={handleChangeSettings} value={tmpdata.color} />
                                    </div>
                                    <div>
                                        <Label>Background Image (URL)</Label>
                                        <Input type="url" name="background" maxlength="255" onChange={handleChangeSettings} value={tmpdata.background} />
                                    </div>
                                    <div>
                                        <Label>Text Color</Label>
                                        <Input type="color" name="textcolor" onChange={handleChangeSettings} value={tmpdata.textcolor} />
                                    </div>
                                    <div>
                                        <Label>Profile Picture (URL)</Label>
                                        <Input type="url" maxlength="255" name="profilepicture" onChange={handleChangeSettings} value={tmpdata.profilepicture} />
                                    </div>
                                    {/* change description to multi line */}

                                    <div>
                                        <Label>Profile Description</Label>
                                        <Input type="text" name="description" maxlength="255" onChange={handleChangeSettings} value={tmpdata.description} />
                                    </div>
                                </form>
                            </CardBody>
                            <CardFooter><CardFooterRight><Button color='success' onClick={changeSettings}>Save</Button></CardFooterRight></CardFooter>
                        </Card>
                    </div>
                    <div className="col-md-8">
                        <Card>
                            <CardHeader>
                                <CardTitle>Links</CardTitle>
                                <CardActions><Button color='success' onClick={openModal}>Add URL</Button></CardActions>
                            </CardHeader>
                            <CardBody><></>
                                <table className='table table-modern'>
                                    <thead>
                                        <tr>
                                            <th>
                                                URL-Name
                                            </th>
                                            <th>
                                                URL
                                            </th>
                                            <th>Icon URL</th>
                                            <th>Color</th>
                                            <th>Order</th>
                                            <th style={{ width: 50 }}>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {links.map((item, key) => (
                                            <tr key={item.id.toString()}>
                                                <td>{item.urlname}</td>
                                                <td>{item.url}</td>
                                                <td>{item.icon}</td>
                                                <td>{item.color}</td>
                                                <td>{item.rank}</td>
                                                <td>
                                                    <Button color='danger' onClick={() => { deleteURL(item.id) }}>Delete</Button>
                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Card style={{ width: '100%', aspectRatio: '16/9' }}>
                            <CardHeader>
                                <CardTitle>Preview</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* Iframe to https.//aioslink.me/name and set everything from data as url params */}
                                {!loading && <iframe src={`https://aioslink.me/${data.name}`} style={{ width: '100%', height: '100%', border: 'none' }}></iframe>}
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default AiosLinkPage;
